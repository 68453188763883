<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';

export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Visualizar animal",
      items: [
        {
          text: "Animais",
          href: "/animals"
        },
        {
          text: "Detalhe",
          active: true
        }
      ],
      animal: null,
      img_url: null,
      expandedMedia: null
    };
  },
  created(){
    const user = JSON.parse(localStorage.getItem('user'));
    let config = {
      method: 'get',
      url: `${process.env.VUE_APP_API}api/v1/admin/animal/${this.$route.params.id}`,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': `Bearer ${user.access_token}`
      }
    };

    axios(config)
    .then( (response) => {
      this.animal = response.data.data.animal

      this.title = `Detalhe - ${this.animal.name}`

      this.animal.medias.map((item, index) => {
        if (index == 0) {
          this.expandedMedia = item.url
        }
      })
    })
    .catch((error) => {
      console.log(error)
      this.$router.push('/pages/error-404')
    });

    this.img_url = process.env.VUE_APP_IMG
  },
  methods: {
    mediaShow(event) {
      const media = event.target.src;
      let expandedMedia = document.getElementById("expandedMedia"); 
      expandedMedia.src = media;
    },
    isVideo(url) {
      if (url.match(/\.(jpeg|jpg|gif|png)$/)) return false;
      else return true;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="animal">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-7">
                <div class="product-detail">
                  <div v-if="animal.medias.length === 0" class="row" >
                    <div class="col d-flex justify-content-center p-5">Sem Foto</div>
                  </div>
                  <div v-else class="row">
                    <div class="col-3">
                      <div
                        class="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="horizontal"
                      >
                         <a
                          v-for="(media, index) in animal.medias"
                          class="nav-link"
                          :id="`product-${index}-tab`"
                          data-toggle="pill"
                          @click="mediaShow($event)"
                          role="tab"
                          :key="index"
                        >
                          <img v-if="isVideo(media.url)"
                            src="@/assets/images/video-player.png"
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                          <img v-else
                            :src="media.url"
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </a>
                      </div>
                    </div>
                    <div class="col-md-8 col-9">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="product-1" role="tabpanel">
                          <div class="product-img">
                            <img
                              id="expandedMedia"
                              :src="expandedMedia"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-5">
                <div class="mt-4 mt-xl-3">
                  <a href="#" class="text-primary">
                    {{
                      animal.categories.map((categorie) => {
                          return categorie.description;
                      }).join(",")
                    }}
                  </a>
                  <h3 class="mt-1 mb-3">{{animal.name}}</h3>

                  <h5 class="font-size-14 mt-2">Registro :</h5>
                  <div class="text-muted">
                    {{animal.registry}}
                  </div>

                  <h5 class="font-size-14 mt-3">Proprietário :</h5>
                  <div class="text-muted">
                    {{animal.users[0].name}}
                  </div>

                  <h5 class="font-size-14 mt-3">Pelagem :</h5>
                  <div class="text-muted">
                    {{animal.coat.description}}
                  </div>

                  <h5 class="font-size-14 mt-3">Raça :</h5>
                  <div class="text-muted">
                    {{animal.breed.description}}
                  </div>

                  <h5 class="font-size-14 mt-3">Data de nascimento :</h5>
                  <div class="text-muted">
                    {{animal.birthday|date}}
                  </div>
                  
                  <h5 class="font-size-14 mt-3">Peso :</h5>
                  <div class="text-muted">
                    {{animal.weight ? animal.weight : "-"}}
                  </div>
                  
                  <h5 class="font-size-14 mt-3">Altura :</h5>
                  <div class="text-muted">
                    {{animal.height ? animal.height : "-"}}
                  </div>
                  
                  <h5 class="font-size-14 mt-3">Histórico de Propriedade :</h5>
                  <div class="text-muted" v-for="(user, index) in animal.users" v-bind:key="index">
                    <span v-if="index+1 === animal.users.length"><i class="bx fas fa-star"></i></span>
                    {{user.name}}
                  </div>

                  <hr class="my-4" />
                  
                </div>
              </div>
            </div>
            <!-- end row -->

          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->

    <b-row v-else>
      <b-col>
        <b-skeleton-img></b-skeleton-img>
      </b-col>
      <b-col>
        <b-skeleton></b-skeleton>
        <div class="m-2"></div>
        <b-skeleton height="60px"></b-skeleton>
        <div class="m-4"></div>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
      </b-col>
    </b-row>
  </Layout>
</template>